<template>
  <div class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ displayName }}</p>
      <!-- <small>Available</small> -->
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" ref="vsDropdown">
      <div class="con-img ml-3">
        <img v-if="photoUrl"
          key="onlineImg"
          :src="photoUrl"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$refs.vsDropdown.$refs.dropdown.click(); $router.push('/account').catch(() => {});">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          
          <li
            v-if="$hasPermission(permissions.management.list.tenants)"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$refs.vsDropdown.$refs.dropdown.click(); $router.push('/management').catch(() => {});">
            <feather-icon icon="UsersIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tenants</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$refs.vsDropdown.$refs.dropdown.click(); logout();">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import auth0 from '@/auth0';
import apiConfig from '@/config/api';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      permissions: apiConfig.permissions
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    ...mapGetters('accounts', [ 'displayName', 'photoUrl' ])
  },
  mounted() {
  },
  methods: {
    logout() {
      auth0.logout();
    }
  }
}
</script>
