<template>
  <div class="flex">
    <div class="search-full-container w-full h-full absolute left-0 top-0" :class="{ flex: showFullSearch }" v-show="showFullSearch">
      <vx-auto-suggest
        ref="navbarSearch"
        :autoFocus="showFullSearch"
        :data="list"
        background-overlay
        class="w-full"
        inputClasses="w-full vs-input-no-border vs-input-no-shdow-focus"
        icon="SearchIcon"
        placeholder="Start typing to filter captures by URL"
        @input="hnd_search_query_update"
        @selected="selected"
        @closeSearchbar="showFullSearch = false"
      >
        <template v-slot:group="{ group_name }">
          <p class="font-semibold text-primary">{{ group_name }}</p>
        </template>

        <!-- Captures Suggestion -->
        <template v-slot:Captures="{ suggestion }">
          <div class="flex items-end leading-none py-1">
            <!-- <feather-icon :icon="suggestion.icon" svgClasses="h-5 w-5" class="mr-4" /> -->
            <span class="mt-1">{{ suggestion.title }}</span>
          </div>
          <p class="text-xs flex" v-if="suggestion.url">
            <feather-icon icon="LinkIcon" svgClasses="h-3 w-3" class="mr-2" />
            <span class="trucate max-w-4/5">{{ suggestion.url | urlPath }}</span>
          </p>
        </template>

        <!-- No Items Slot -->
        <template v-slot:noResult>
          <div class="flex items-center">
            <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="mr-4" />
            <span>No results found.</span>
          </div>
        </template>
      </vx-auto-suggest>

      <div class="absolute right-0 h-full z-50">
        <feather-icon v-if="loading" icon="LoaderIcon" class="px-4 cursor-pointer h-full close-search-icon spinner" />
        <feather-icon v-else icon="XIcon" class="px-4 cursor-pointer h-full close-search-icon" @click="showFullSearch = false" />
      </div>
    </div>
    <feather-icon icon="SearchIcon" @click="showFullSearch = true" class="cursor-pointer navbar-fuzzy-search mr-4" />
  </div>
</template>

<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';
import { debounce } from '@/plugins/helper.js';
export default {
  components: {
    VxAutoSuggest
  },
  data() {
    return {
      loading: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      showFullSearch: false,
      list: {}
    };
  },
  methods: {
    selected(item) {
      const capture = item['Captures'];
      this.showFullSearch = false;
      this.$router.push(`/captures/${capture.id}`).catch(() => {});
    },
    hnd_search_query_update: debounce(async function(query) {
      // Show overlay if any character is entered
      this.$store.dispatch('TOGGLE_CONTENT_OVERLAY', query ? true : false);
      if (!query) return;

      this.loading = true;
      await this.$store
        .dispatch('captures/list', {
          dontCommit: true,
          params: {
            q: query
          },
          paging: { size: 10, page: 1 },
          config: {
            disablePreload: true
          }
        })
        .then(data => {
          this.$set(this, 'list', {
            Captures: {
              key: 'title',
              data: data.items.map(item => ({
                ...item,
                ...(!item.title
                  ? {
                      title: this.$options.filters.urlPath(item.url)
                    }
                  : {})
              }))
            }
          });
        });
      this.loading = false;
    }, 350)
  }
};
</script>
