<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">
    <v-nav-menu
      :navMenuItems = "navMenuItems"
      title         = "Page Witness"
      parent        = ".layout--main" />

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay" />

    <!-- Navbar -->
    <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
      <the-navbar-horizontal
        :navbarType= "navbarType"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

      <div style="height: 62px" v-if="navbarType === 'static'"></div>

      <h-nav-menu
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
        :navMenuItems="navMenuItems" />
    </template>

    <template v-else>
      <the-navbar-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />
    </template>
    <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <section v-if="$route.meta.breadcrumb || routeTitle" class="router-header-area mb-10">
                <div class="router-header flex flex-wrap items-center mb-2">
                  <div
                    class="content-area__heading"
                    :class="{'pr-4 border-0 md:border-r border-solid border-grey-light max-w-xs' : $route.meta.breadcrumb}">
                    <h1 class="mb-1 text-2xl truncate">{{ getRouteTitle() }}</h1>
                  </div>

                  <!-- BREADCRUMB -->
                  <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

                  <!-- DROPDOWN -->
                  <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer" v-if="actions.type === 'dropdown' && actions.items.length > 0">
                    <vs-button radius icon="icon-more-vertical" icon-pack="feather" />
                    <vs-dropdown-menu class="w-auto whitespace-no-wrap">
                      <vs-dropdown-item v-for="(action, index) in actions.items" :key="index" v-show="getActionVisibility(action)">
                        <div @click="getActionMethod(action)" class="flex items-center">
                          <feather-icon v-if="action.icon" :icon="getActionIcon(action)" class="inline-block mr-2" svgClasses="w-4 h-4" />
                          <span class="capitalize">{{ getActionText(action) }}</span>
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>

                  <div class="ml-auto md:block hidden view-controls" v-if="actions.type === 'button' && actions.items.length > 0">
                    <vs-button
                      v-for="(action, index) in actions.items" :key="index"
                      v-show="getActionVisibility(action)"
                      @click="getActionMethod(action)"
                      :class="{ 'ml-2': index > 0 }"
                      v-bind="action.attrs">
                      <feather-icon v-if="action.icon" :icon="getActionIcon(action)" class="mr-2 relative" svgClasses="w-4 h-4" style="top: 0.15rem;" />
                      <span class="capitalize">
                        {{ getActionText(action) }}
                      </span>
                    </vs-button>
                  </div>
                </div>
                <div class="router-subheader" v-if="$route.meta.subTitle">
                  <div class="vx-row">
                    <div class="vx-col sm:w-3/5">
                      <p class="text-sm">{{ getSubtitle($route.meta.subTitle) }}</p>
                    </div>
                  </div>
                </div>
              </section>
            </transition>

            <div class="content-area__content">

              <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <section>
                  <vs-alert :active="$route.meta.permission ? !$hasPermission($route.meta.permission) : false">You do not have permission to view this page.</vs-alert>
                  <router-view
                    :key="viewKey"
                    ref="view"
                    v-if="$route.meta.permission ? $hasPermission($route.meta.permission) : true"
                    @changeRouteTitle="changeRouteTitle"
                    @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
                </section>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>


<script>
import BackToTop           from 'vue-backtotop'
import HNavMenu            from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue"
import navMenuItems        from "@/layouts/components/vertical-nav-menu/navMenuItems.js"
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical   from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter           from '@/layouts/components/TheFooter.vue'
import themeConfig         from '@/../themeConfig.js'
import VNavMenu            from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu
  },
  data() {
    return {
      viewKey           : 0,
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      navMenuItems      : navMenuItems,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle
    }
  },
  watch: {
    "$route"() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff"
      this.updateNavbarColor(color)
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val)
    }
  },
  computed: {
    bodyOverlay() { return this.$store.state.bodyOverlay },
    contentAreaClass() {
      if(this.mainLayoutType === "vertical") {
        if      (this.verticalNavMenuWidth == "default") return "content-area-reduced"
        else if (this.verticalNavMenuWidth == "reduced") return "content-area-lg"
        else return "content-area-full"
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full"
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType == 'hidden',
        'footer-sticky': this.footerType == 'sticky',
        'footer-static': this.footerType == 'static',
      }
    },
    isAppPage() {
      return this.$route.meta.no_scroll
    },
    isThemeDark()     { return this.$store.state.theme == 'dark' },
    layoutTypeClass() { return `main-${this.mainLayoutType}` },
    mainLayoutType()  { return this.$store.state.mainLayoutType  },
    navbarClasses()   {
      return {
        'navbar-hidden'   : this.navbarType == 'hidden',
        'navbar-sticky'   : this.navbarType == 'sticky',
        'navbar-static'   : this.navbarType == 'static',
        'navbar-floating' : this.navbarType == 'floating',
      }
    },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth },
    windowWidth()          { return this.$store.state.windowWidth },
    actions () {
      const defaults = {
        type: 'button',
        items: []
      }
      try {
        return {
          ...defaults,
          ...this.$route.meta.actions
        }
      } catch (error) {
        return defaults
      }
    },
    getActionText() {
      const $ctrl = this;
      return function (action) {
        const text = action.text;
        if (text) {
          if (typeof text === 'function') return text($ctrl);
          return text;
        }
      }
    },
    getActionIcon() {
      const $ctrl = this;
      return function (action) {
        const icon = action.icon;
        if (icon) {
          if (typeof icon === 'function') return icon($ctrl);
          return icon;
        }
      }
    },
    getActionMethod() {
      const $ctrl = this;
      return function (action) {
        const url = action.url;
        if (url) {
          if (typeof url === 'function') return $ctrl.$router.push(url($ctrl));
          return $ctrl.$router.push(url);
        }
        const handler = action.handler;
        if (handler) {
          try {
            return handler(this.$refs.view, this.reloadRouterView);
          } catch (error) {
            return null;
          }
        }
        
        throw new Error('A [url] or [handler] is missing');
      }
    },
    getActionVisibility() {
      return (action) => {
        const disabled = action.disabled;
        const view = this.$refs.view;
        const visibility = () => {
          if (disabled !== null && disabled !== undefined) {
            if (typeof disabled === 'function')  {
              return !disabled(this.$refs.view)
            }
            return disabled;
          }
          return true;
        }
        if (this.isMounted && view && view.isMounted) {
          return visibility();
        }
        return visibility();
      }
    },
    getRouteTitle() {
      const view = this.$refs.view;
      const routeTitle = () => {
        if (typeof this.routeTitle === 'function')  {
          return this.routeTitle(this.$refs.view)
        }
        return this.routeTitle;
      }
      return () => {
        if (this.isMounted && view && view.isMounted) {
          return routeTitle();
        }
        return routeTitle();
      }
    },
    getSubtitle() {
      const view = this.$refs.view;
      const subTitle = (prop) => {
        if (typeof prop === 'function')  {
          return prop(this.$refs.view)
        }
        return prop;
      }
      return (prop) => {
        if (this.isMounted && view && view.isMounted) {
          return subTitle(prop);
        }
        return subTitle(prop);
      }
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      if (val == "#fff") this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    setNavMenuVisibility(layoutType) {
      if((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === "vertical" && this.windowWidth < 1200)) {
        this.$store.dispatch('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      }
      else {
        this.$store.dispatch('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    reloadRouterView() {
      this.viewKey += 1;
    }
  },
  created() {
    const color = this.navbarColor == "#fff" && this.isThemeDark ? "#10163a" : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)
  }
}

</script>

