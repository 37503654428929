/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import apiConfig from '@/config/api';
const { permissions } = apiConfig;
const { captures, monitors, templates, pagestatus, emails } = permissions;

export default [
  {
    url: '/',
    name: 'Dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon'
  },
  {
    url: '/captures',
    name: 'Captures',
    slug: 'captures',
    icon: 'CameraIcon',
    permission: captures.list
  },
  {
    url: '/monitoring',
    name: 'Monitoring/Tests',
    slug: 'monitoring',
    icon: 'ActivityIcon',
    permission: monitors.list
  },
  {
    url: '/emails',
    name: 'Received Emails',
    slug: 'emails',
    icon: 'MailIcon',
    permission: emails.received.list
  },
  {
    url: '/summary',
    name: 'Page Status',
    slug: 'summary',
    icon: 'ZapIcon',
    permission: pagestatus.list
  },
  {
    url: '/templates',
    name: 'Templates',
    slug: 'templates',
    icon: 'MonitorIcon',
    permission: templates.list
  },
  {
    url: '/account',
    name: 'My Account',
    slug: 'account',
    icon: 'UserIcon'
  }
];
